


































































































































import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class TrialEnd extends Vue {
  cancelClicked: boolean = false;
  reason: string | null = null;
  loading: boolean = false;

  claimPremiumClicked() {
    this.loading = true;
    setTimeout(() => {
      this.$router.push({name: 'plans' });
      this.loading = false;
    }, 1400);
  }

  back() {
    this.cancelClicked = false;
    this.reason = null;
  }
}
